import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { PreciosService } from './precios.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IImage } from 'ng-simple-slideshow';
import { BehaviorSubject, interval } from 'rxjs';
import {

  shakeOnEnterAnimation,
} from 'angular-animations';
import { Observable } from 'rxjs';
//https://www.npmjs.com/package/ng-simple-slideshow


export enum KEY_CODE {
  ENTER = 13
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    shakeOnEnterAnimation({duration: 1000})
  ]
})

export class AppComponent implements OnInit, AfterViewInit{

  
  elementType = 'url';
  valueqr = 'https://serra.liberasoft.com.ar/lectormovil/';

  title = 'lectordeprecios';
  value = 0;
  codigo:string='';

  prdNombre:string;
  precioFinal:string;
  oferta:number;
  prdCodigo:string;
  prdNroCodigo:number;
  prpCodigoProveedor:string;
  Rubro:string;
  SubRubro:string;
  Clasificacion:string;
  Marca:string;
  img:string;
  extra:number;

  
  _showprecio:boolean;
  _noencontrado:boolean;

  timeLeft: number = 30;
  interval;
  animState: BehaviorSubject<boolean>;
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode==KEY_CODE.ENTER){
      this.pauseTimer();
      this.timeLeft = 30;
      this.obtenerProducto();
      this.codigo = '';
    }else{
      this.codigo = this.codigo + event.key
    }
  }
/*
  onKey(event: KeyboardEvent) { asd
    if  (event.keyCode==KEY_CODE.ENTER){
      alert('entra');
      this.obtenerProducto();
      this.codigo = '';
    }
    this.codigo += event.key;
  }
*/
  constructor(
    private http: PreciosService,
    private _snackBar: MatSnackBar,
    public cd: ChangeDetectorRef
    ) {
      this.animState = new BehaviorSubject(false);
     }

  ngOnInit(): void {
    this._showprecio=false;
    this._noencontrado=false;
  
  
  }
  ngAfterViewInit() {
  
    this.onQrMessageAnimation();
  }
onQrMessageAnimation() {
  interval(2000).subscribe(
    (value: number) => {
     
      this.animState.next(!this.animState.value);
      this.cd.detectChanges();
    },
    (error: any) => {
      console.log('error');
    },
    () => {
      console.log('observable completed !');
    }
  );
}

 

startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
        //console.log(this.timeLeft);
        if (this.timeLeft===0){
          this._showprecio = false;
          this._noencontrado = false;
          this.pauseTimer();
        }
      } else {
        this.timeLeft = 30;
      }
    },1000)
  }

pauseTimer() {
    clearInterval(this.interval);
}

  obtenerProducto(){
    this.http.getprecio(this.codigo).subscribe((response) => {
      switch(response.status) { 
        case "warning": { 
            this._noencontrado=true;
            this.startTimer();
            break;
        } 
        case "success": { 
            this.prdNombre = response.data['prdNombre'];
            this.precioFinal = response.data['precioFinal'];
            this.oferta = response.data['oferta'];
            this.prdCodigo = response.data['prdCodigo'];
            this.prdNroCodigo = response.data['prdNroCodigo'];
            this.prpCodigoProveedor = response.data['prpCodigoProveedor'];
            this.Rubro  = response.data['Rubro'];
            this.SubRubro = response.data['SubRubro'];
            this.Clasificacion = response.data['Clasificacion'];
            this.Marca = response.data['Marca'];
            this.img = response.data['img'];
            this.extra = response.data['extra'];               
            this._showprecio=true;
            this._noencontrado=false;
            this.startTimer();
           break; 
        }  
     } 
    });
  }


}
function takeWhile(arg0: (value: any) => boolean): import("rxjs").OperatorFunction<number, unknown> {
  throw new Error('Function not implemented.');
}

