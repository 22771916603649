<p>

    <mat-toolbar
    color="primary"
    style="min-height: 100px !important; height: 100px !important;padding: 2em;"
    >
    <img src="assets/logo.png" alt="Italian Trulli" 
   
    >   
    <span class="example-spacer"></span>

       <span *ngIf="this.animState.value == false" class="qrText"
    
    >Escaneá y llevá el lector en tu celular <mat-icon>forward</mat-icon></span>

    <span *ngIf="this.animState | async" class="qrText"
    [@shakeOnEnter]="this.animState | async"
    
    >Escaneá y llevá el lector en tu celular <mat-icon>forward</mat-icon> </span>
 
    <ngx-qrcode 
      [elementType]="elementType" 
      [value] = "valueqr"
      cssClass = "aclass"
      errorCorrectionLevel = "L">
    </ngx-qrcode>

    </mat-toolbar>


</p>
<div *ngIf="_noencontrado===true" style="height: 605px;text-align: center;"  >
    <span style="font-size:45px;font-family:FiraSansBold;line-height:50px">
        Oops... NO ENCONTRAMOS EL PRODUCTO.. POR FAVOR, DIRIGIRSE A MOSTRADOR PARA MAS INFORMACIÓN
    </span>
</div>
<div *ngIf="_showprecio===false && _noencontrado===false"
     style="height: 605px">
    <mat-video 
                src="assets/videos/fegime-latam.mp4"
                [autoplay]="true" 
                [preload]="true" 
                [fullscreen]="false"
                [loop]="true"
                [muted]="true">
    </mat-video>
</div>
<div *ngIf="_showprecio===true && _noencontrado===false">
  <mat-grid-list cols="2" 
                 rowHeight="200px"
                    >
      <mat-grid-tile [rowspan]="3">
          <img 
          
          src="{{img}}" onError="this.src='https://tienda.electricidadserra.com.ar/img/nofoto.png'" >
      </mat-grid-tile>
      <mat-grid-tile >
          <span style="font-size:45px;font-family:FiraSansBold;line-height:50px">{{prdNombre}} </span>
      </mat-grid-tile>
      <mat-grid-tile  >
          <div style="position: absolute;left: 5px;top: 5px;" *ngIf="oferta===1">
              <span style="font-size:30px;font-family:FiraSansBold;color:green;" >OFERTA!!!</span>
          </div>  
          <br>
          <div style="position: absolute;left: 5px;top: 60px;">
              <span style="font-size:60px;font-family:FiraSansBold;color:red;">$ {{precioFinal}}</span> IVA INCLUIDO
          </div>  
          <div style="position: absolute;left: 5px;top: 120px;">
              <span style="font-size:20px;font-family:FiraSansRegular;">CÓDIGO: {{prdCodigo}}{{prdNroCodigo}} COD. PRV. {{prpCodigoProveedor}}</span>
          </div>  
          <div style="position: absolute;left: 5px;top: 160px;">
              <span style="font-size:15px;font-family:FiraSansRegular;">CATEGORÍA: {{Rubro}} / {{SubRubro}}</span>
          </div>  
          
      </mat-grid-tile>
      <mat-grid-tile>
          <div *ngIf="extra===1" >

          {{extra}}
          </div>
      </mat-grid-tile>
  </mat-grid-list>
</div>  
  <footer style="text-align: center;">
      <!-- Copyright -->
      <div style="background-color:blue;height: 30px;padding: 0.5em;font-size:20px;font-family:FiraSansRegular;color:white;">
          https://tienda.electricidadserra.com.ar/      
      </div>
      <!-- Copyright -->
  </footer>