import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { ResponseI } from './ResponseI';

@Injectable({
  providedIn: 'root'
})
export class PreciosService {
  URL_SERVER:string= 'https://serra.liberasoft.com.ar/precios/';
  _httpcli:HttpClient;

  constructor(private http: HttpClient) {
    this._httpcli = http;
   }

  httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
  }

  getprecio(barcode:string):Observable<ResponseI>{

    //barcode = '4011209270268';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Cache-Control', 'no-cache');
    headers.append('Authorization', '{"usr":"root","pass":"123456"}');
    
    let params = new HttpParams();
    params = params.append('barcode', barcode);
    const options = { params: params, headers: headers };
    
    return this._httpcli.post<ResponseI>(this.URL_SERVER + "getpreciobybarcode",params, options );
  }

 // Handle API errors
 handleError(error: HttpErrorResponse) {
   
   if (error.error instanceof ErrorEvent) {
     // A client-side or network error occurred. Handle it accordingly.
     alert(error.error.message);
     console.error('An error occurred:', error.error.message);
   } else {
     // The backend returned an unsuccessful response code.
     // The response body may contain clues as to what went wrong,
     console.error(
       `Backend returned code ${error.status}, ` +
       `body was: ${error.error}`);
   }
   // return an observable with a user-facing error message
   return throwError(
     'Something bad happened; please try again later.');
 };

}
